// https://github.com/bugsnag/bugsnag-js/tree/next/examples/js/nextjs
import React from "react";
import Error, { ErrorProps } from "next/error";
import Bugsnag from "services/bugsnag";
import { NextPage, NextPageContext } from "next";

const ErrorPage: NextPage<ErrorProps> = (props) => {
  return <Error {...props} />;
};

export default ErrorPage;

ErrorPage.getInitialProps = async (ctx: NextPageContext) => {
  if (ctx.err) Bugsnag.notify(ctx.err);
  return Error.getInitialProps(ctx);
};
